import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { TextFieldStyle } from 'components';
import { toastMessage } from 'helpers';
import { UserContext } from 'providers';

export const Rename = ({
  id,
  renameEntity,
  successMessage,
  inputFieldStyle,
  entityName,
  setIsRenameInProgress,
  setSelectedEntities,
  refetchEntity
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { refetchLabels } = useContext(UserContext);

  const submitRenameEntity = (values, { setSubmitting }) => {
    renameEntity(values).then(() => {
      refetchEntity();
      refetchLabels();
      toastMessage(enqueueSnackbar, closeSnackbar, t(successMessage), 'success');
    });
    setSubmitting(false);
    setIsRenameInProgress(false);
    setSelectedEntities([]);
  };

  const renameEntitySchema = Yup.object().shape({
    name: Yup.string().max(255, t('tooLong')).trim().required(t('required'))
  });

  const hideTextFieldOnEsc = e => {
    if (e.keyCode === 27) {
      setIsRenameInProgress(false);
    }
  };

  return (
    <Formik
      initialValues={{
        id: id,
        name: entityName
      }}
      validationSchema={renameEntitySchema}
      onSubmit={submitRenameEntity}>
      {({ touched, errors, getFieldProps, isSubmitting, isValid, dirty, handleBlur }) => (
        <Form onBlur={() => setIsRenameInProgress(false)} style={{ width: inputFieldStyle.width }}>
          <TextFieldStyle
            autoFocus
            data-cy={'renameInput'}
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            sx={{
              '&.MuiFormControl-root': {
                paddingTop: !isValid && '15px',
                width: '100%'
              },
              '& .MuiOutlinedInput-root': {
                bgcolor: theme.palette.gray.lighter
              },
              '& .Mui-focused': {
                border: isValid && '1px solid',
                height: inputFieldStyle.height,
                borderColor: isValid && theme.palette.gray.main,
                '& .MuiOutlinedInput-notchedOutline': {
                  border: isValid && 'none'
                }
              }
            }}
            InputProps={{
              sx: {
                fontFamily: theme.typography.fontFamilyPrimaryMedium
              }
            }}
            onKeyUp={e => {
              handleBlur(e);
              hideTextFieldOnEsc(e);
            }}
            onClick={e => e.stopPropagation()}
          />
          <Button
            type='submit'
            sx={{ display: 'none' }}
            disabled={isSubmitting || !(isValid && dirty)}
          />
        </Form>
      )}
    </Formik>
  );
};

Rename.propTypes = {
  id: PropTypes.number,
  entityName: PropTypes.string,
  entityToRename: PropTypes.string,
  setIsRenameInProgress: PropTypes.func,
  setSelectedEntities: PropTypes.func,
  refetchEntity: PropTypes.func,
  renameEntity: PropTypes.func,
  successMessage: PropTypes.string,
  inputFieldStyle: PropTypes.object
};
