import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const CircleScore = ({
  backgroundColor,
  color,
  score,
  unavailable,
  width,
  height,
  fontSize,
  cursor = 'default'
}) => {
  const theme = useTheme();

  return (
    <div
      data-testid='circleScoreContainer'
      style={{
        cursor: cursor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        borderRadius: '50%'
      }}>
      <p
        style={{
          color: color,
          fontFamily: theme.typography.fontFamilyPrimarySemibold,
          fontSize: theme.typography.pxToRem(fontSize),
          lineHeight: 'normal'
        }}>
        {unavailable ? '' : score}
      </p>
    </div>
  );
};

CircleScore.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  score: PropTypes.number,
  unavailable: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  cursor: PropTypes.string
};
