import { alpha } from '@mui/material/styles';

const createGradient = (color1, color2) => {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
};

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  800: '#212B36',
  900: '#161C24',
  5008: alpha('#919EAB', 0.08),
  50012: alpha('#919EAB', 0.12),
  50016: alpha('#919EAB', 0.16),
  50024: alpha('#919EAB', 0.24),
  50032: alpha('#919EAB', 0.32),
  50048: alpha('#919EAB', 0.48),
  50056: alpha('#919EAB', 0.56),
  50080: alpha('#919EAB', 0.8)
};

const GRAY = {
  white: '#FFFFFF',
  50: '#F9FAFB',
  lightest: '#F4F6F8',
  lighter: '#EAEDF0',
  light: '#DFE3E8',
  400: '#B8C1CC',
  500: '#94A2B2',
  medium: '#919EAB',
  main: '#515F71',
  dark: '#161E26',
  darker: '#222C38'
};

const PRIMARY = {
  main: '#16161D',
  90: alpha('#16161D', 0.9),
  80: alpha('#16161D', 0.8),
  70: alpha('#16161D', 0.7),
  60: alpha('#16161D', 0.6),
  50: alpha('#16161D', 0.5),
  40: alpha('#16161D', 0.4),
  30: alpha('#16161D', 0.3),
  20: alpha('#16161D', 0.2),
  10: alpha('#16161D', 0.1)
};

const SECONDARY = {
  main: '#007970',
  light: '#D8F2ED',
  90: alpha('#007970', 0.9),
  80: alpha('#007970', 0.8),
  70: alpha('#007970', 0.7),
  60: alpha('#007970', 0.6),
  50: alpha('#007970', 0.5),
  40: alpha('#007970', 0.4),
  30: alpha('#007970', 0.3),
  20: alpha('#007970', 0.2),
  10: alpha('#007970', 0.1)
};

const TERTIARY = {
  dark: '#62BFB0',
  main: '#7BD1C3',
  90: alpha('#7BD1C3', 0.9),
  80: alpha('#7BD1C3', 0.8),
  70: alpha('#7BD1C3', 0.7),
  60: alpha('#7BD1C3', 0.6),
  50: alpha('#7BD1C3', 0.5),
  40: alpha('#7BD1C3', 0.4),
  30: alpha('#7BD1C3', 0.3),
  20: alpha('#7BD1C3', 0.2),
  10: alpha('#7BD1C3', 0.1)
};

const QUATERNARY = {
  dark: '#C194F6',
  main: '#D0AAFD',
  90: alpha('#D0AAFD', 0.9),
  80: alpha('#D0AAFD', 0.8),
  70: alpha('#D0AAFD', 0.7),
  60: alpha('#D0AAFD', 0.6),
  50: alpha('#D0AAFD', 0.5),
  40: alpha('#D0AAFD', 0.4),
  30: alpha('#D0AAFD', 0.3),
  20: alpha('#D0AAFD', 0.2),
  10: alpha('#D0AAFD', 0.1)
};

const QUINARY = {
  main: '#EFE9FF',
  90: alpha('#EFE9FF', 0.9),
  80: alpha('#EFE9FF', 0.8),
  70: alpha('#EFE9FF', 0.7),
  60: alpha('#EFE9FF', 0.6),
  50: alpha('#EFE9FF', 0.5),
  40: alpha('#EFE9FF', 0.4),
  30: alpha('#EFE9FF', 0.3),
  20: alpha('#EFE9FF', 0.2),
  10: alpha('#EFE9FF', 0.1)
};
const INFO = {
  lighter: '#F4F6F8',
  main: '#637381'
};
const SUCCESS = {
  light: '#E4F6F3',
  main: '#7BD1C3'
};
const WARNING = {
  lighter: '#FFF2C8',
  main: '#FFD23F'
};

const ERROR = {
  lighter: '#FFE3E3',
  main: '#FD6F6F'
};

const INVITED = {
  main: GREY[300],
  dark: GREY[50080]
};

const ACTIVE = {
  main: '#C5F1EC',
  dark: '#1DD5C0'
};

const UNKNOWN = {
  main: GREY[300],
  dark: GREY[50080]
};

const LEVEL_1 = {
  main: '#C5F1EC',
  dark: '#1DD5C0'
};

const LEVEL_2 = {
  main: '#C7EFFF',
  dark: '#48C9FE'
};

const LEVEL_3 = {
  main: '#FFF2C8',
  dark: '#FFD23F'
};

const LEVEL_4 = {
  main: '#FFE4C4',
  dark: '#FFAF52'
};
const LEVEL_5 = {
  main: '#FFE3E3',
  dark: '#FD6F6F'
};

const SELECTED_WORD = {
  main: '#ED0909'
};

const DISABLED_USER = {
  main: '#EC4343'
};

const PARAGRAPH_SUGGESTIONS = {
  main: '#E4F6F3',
  dark: '#007970'
};

const SUGGESTIONS = {
  main: '#F3E8FF',
  dark: '#A378FF'
};

const LOW_PRIORITY = {
  main: '#48C9FE'
};

const MEDIUM_PRIORITY = {
  main: '#FFD23F'
};

const HIGH_PRIORITY = {
  main: '#FD6F6F'
};

const DISABLED = {
  main: '#707070'
};

const EDITOR_INDICATOR = {
  online: '#22E732',
  offline: '#637381'
};

const PRODUCTIVITY_VALUES = {
  main: '#C28EFF'
};

const STARTING_SCORE = {
  main: '#1DD5C0'
};

const ALL_DOCS = {
  main: '#007970'
};

const EDITED = {
  main: '#D0AAFD'
};

const BAD_SCORE = {
  dark: '#FD6F6F'
};

const AVERAGE_SCORE = {
  dark: '#FFD23F'
};

const GOOD_SCORE = {
  dark: '#7BD1C3'
};

const REPHRASE = {
  main: '#5661C3',
  dark: '#885BE3'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const TOTAL = {
  main: '#83909B'
};

const NO_SUGGESTIONS = {
  main: '#00BAE9'
};

const COMPLEX_SENTENCE = {
  main: '#FD6F6F'
};

const COMPLEX_WORD = {
  main: '#FFC914',
  light: '#FFF0C2 '
};

const WRITING_STYLE = {
  main: '#885BE3',
  light: '#DBCDF7'
};

const CLARITY = {
  main: '#018AC1',
  light: '#B8EBFF'
};

const COMPLEX_CONCEPT = {
  main: '#FF8900',
  light: '#FFDDC5'
};

const LOW_IMPACT = {
  main: '#FFD23F'
};

const MEDIUM_IMPACT = {
  main: '#FFAF52'
};

const HIGH_IMPACT = {
  main: '#FD6F6F'
};

const HIGHLIGHTED_COMMENT = {
  main: '#B2C4FF'
};

const AVATAR_COLORS = [
  '#D4145A',
  '#F765A3',
  '#FF8900',
  '#FFD23F',
  '#95C730',
  '#1DD5C0',
  '#10CA86',
  '#3F6CFF',
  '#6771CA',
  '#885BE3'
];

const LIGHT_AVATAR_COLORS = [
  '#F1B2C9',
  '#FDD0E3',
  '#FFDDC5',
  '#FFDDC5',
  '#DFEEC0',
  '#BBF2EC',
  '#B7EFDA',
  '#B2C4FF',
  '#CCCFED',
  '#DBCDF7'
];

const IMPACT_SCORE = {
  main: '#1DD5C0'
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  gray: { ...GRAY },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  tertiary: { ...TERTIARY },
  quaternary: { ...QUATERNARY },
  quinary: { ...QUINARY },
  info: { ...INFO },
  intelligibility_Level0: { ...UNKNOWN },
  intelligibility_Level1: { ...LEVEL_1 },
  intelligibility_Level2: { ...LEVEL_2 },
  intelligibility_Level3: { ...LEVEL_3 },
  intelligibility_Level4: { ...LEVEL_4 },
  intelligibility_Level5: { ...LEVEL_5 },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  invited: { ...INVITED },
  active: { ...ACTIVE },
  selectedWord: { ...SELECTED_WORD },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[50024],
  action: {
    hover: GREY[5008],
    selected: GREY[50016],
    disabled: GREY[50080],
    disabledBackground: GREY[50024],
    focus: GREY[50024],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  },
  disabledUser: { ...DISABLED_USER },
  paragraphSuggestions: { ...PARAGRAPH_SUGGESTIONS },
  suggestions: { ...SUGGESTIONS },
  lowPriority: { ...LOW_PRIORITY },
  mediumPriority: { ...MEDIUM_PRIORITY },
  highPriority: { ...HIGH_PRIORITY },
  disabled: { ...DISABLED },
  editorIndicator: { ...EDITOR_INDICATOR },
  productivityValues: { ...PRODUCTIVITY_VALUES },
  startingScore: { ...STARTING_SCORE },
  allDocs: { ...ALL_DOCS },
  edited: { ...EDITED },
  complexityLevel_bad: { ...BAD_SCORE },
  complexityLevel_average: { ...AVERAGE_SCORE },
  complexityLevel_good: { ...GOOD_SCORE },
  total: { ...TOTAL },
  noSuggestions: { ...NO_SUGGESTIONS },
  highlightedComment: { ...HIGHLIGHTED_COMMENT },
  complexSentence: { ...COMPLEX_SENTENCE },
  complexWord: { ...COMPLEX_WORD },
  writingStyle: { ...WRITING_STYLE },
  clarity: { ...CLARITY },
  complexConcept: { ...COMPLEX_CONCEPT },
  lowImpact: { ...LOW_IMPACT },
  mediumImpact: { ...MEDIUM_IMPACT },
  highImpact: { ...HIGH_IMPACT },
  avatarColors: { ...AVATAR_COLORS },
  lightAvatarColors: { ...LIGHT_AVATAR_COLORS },
  rephrase: { ...REPHRASE },
  impactScore: { ...IMPACT_SCORE }
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GRAY.main, disabled: GRAY.main },
    background: { paper: '#fff', default: '#F4F6F8', neutral: GREY[200] },
    action: { active: GRAY.main, ...COMMON.action }
  },
  dark: {
    ...COMMON,
    text: { primary: '#fff', secondary: GRAY.main, disabled: GRAY.main },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[50016] },
    action: { active: GRAY.main, ...COMMON.action }
  }
};

export default palette;
