import { createStore } from 'zustand';

import { actionTypesPerCategory } from 'helpers';

import { documentDataStore } from './documentDataStore';
import { editorDataStore } from './editorDataStore';

export const createEditorStore = documentId => {
  return createStore()((set, get) => ({
    documentId: documentId,
    ...documentDataStore(set),
    ...editorDataStore(set),
    setDocumentSuggestions: newSuggestions =>
      set({
        documentSuggestions: newSuggestions,
        filteredDocumentSuggestions: filterSuggestions(
          newSuggestions,
          get().selectedSuggestionFilterOptions
        )
      }),
    setSelectedSuggestionFilterOptions: newFilterOptions =>
      set({
        selectedSuggestionFilterOptions: newFilterOptions,
        filteredDocumentSuggestions: filterSuggestions(get().documentSuggestions, newFilterOptions)
      }),
    reset: () => {
      get().resetDocumentDataStore();
      get().resetEditorDataStore();
      set({ documentId: 0 });
    }
  }));
};

const filterSuggestions = (documentSuggestions, selectedSuggestionFilterOptions) => {
  const selectedCategories = selectedSuggestionFilterOptions['category'];
  const selectedImpactLevels = selectedSuggestionFilterOptions['impact'];
  const selectedActionTypes = selectedCategories.flatMap(c => actionTypesPerCategory[c]);

  let filteredSuggestions = documentSuggestions;
  if (selectedActionTypes.length > 0) {
    filteredSuggestions = filteredSuggestions.filter(s => selectedActionTypes.includes(s.action));
  }
  if (selectedImpactLevels.length > 0) {
    filteredSuggestions = filteredSuggestions.filter(s =>
      selectedImpactLevels.includes(s.suggestionImpact)
    );
  }

  return filteredSuggestions;
};
