import axios from 'axios';
import qs from 'qs';

import { getApiCoreUrl, upload } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Users`;

const getAccessibleUsers = async () => {
  const url = `${baseUrl}/accessible_users`;
  const response = await axios.get(url);
  return response.data;
};

const getUsers = (page = 0, rowsPerPage = 10, orderBy = '', search = '') => {
  const url = `${baseUrl}?${qs.stringify({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    orderBy: orderBy,
    search: search
  })}`;

  return axios.get(url).then(response => {
    return response.data;
  });
};

const getDocumentFilterUsers = () => {
  const url = `${baseUrl}/document_filter_users`;
  return axios.get(url).then(response => {
    return response.data;
  });
};

const getUserById = id => {
  const url = `${baseUrl}/${id}`;
  return axios.get(url).then(response => {
    return response.data;
  });
};

const createUsers = (data, authProvider) => {
  return axios.post(baseUrl, data, {
    headers: {
      AuthProvider: authProvider
    }
  });
};

const updateUser = user => {
  return axios.put(baseUrl, user).then(res => {
    return res.data;
  });
};

const updateUserById = async user => {
  const url = `${baseUrl}/${user.id}`;
  const res = await axios.put(url, user, {
    headers: {
      AuthProvider: user.authProvider
    }
  });
  return res.data;
};

const deleteUser = (id, authProvider) => {
  const url = `${baseUrl}/${id}`;
  return axios.delete(url, {
    headers: {
      AuthProvider: authProvider
    }
  });
};

const deleteMultipleUsers = data => {
  const ids = data.map(d => `ids=${d}`);
  const url = `${baseUrl}?${ids.join('&')}`;
  return axios.delete(url, {
    headers: {
      AuthProvider: 'Firebase' //Bulk delete could contain users from diff providers, only those from firebase should be removed from the firebase app.
    }
  });
};

const resendInvite = (email, authProvider) => {
  const url = `${baseUrl}/resend_invitation`;
  const obj = { email: email };
  return axios.post(url, obj, {
    headers: {
      AuthProvider: authProvider
    }
  });
};

const uploadProfilePicture = file => {
  return upload(file, `${baseUrl}/profile_picture`);
};

const removeProfilePicture = () => {
  return axios.delete(`${baseUrl}/remove_profile_picture`);
};

export const userService = {
  getAccessibleUsers,
  getUsers,
  getUserById,
  createUsers,
  updateUser,
  updateUserById,
  deleteUser,
  deleteMultipleUsers,
  resendInvite,
  uploadProfilePicture,
  removeProfilePicture,
  getDocumentFilterUsers
};
