import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { MHidden } from 'components';
import { useCollapseDrawer } from 'hooks';

import { MenuContent } from './MenuContent';

const DRAWER_WIDTH = 240;
const COLLAPSE_WIDTH = 72;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: '1s'
    })
  }
}));

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar, setIsSwitchAccountDialogOpen }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { collapseClick, collapseHover } = useCollapseDrawer();
  const [collapse, setCollapse] = useState(JSON.parse(localStorage.getItem('isCollapse')));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle
      sx={{
        width: {
          lg: collapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}>
      <MHidden width='lgUp'>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'transparent' }
          }}>
          <MenuContent setIsSwitchAccountDialogOpen={setIsSwitchAccountDialogOpen} />
        </Drawer>
      </MHidden>

      <MHidden width='lgDown'>
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              borderRight: 0,
              width: DRAWER_WIDTH,
              transition: theme.transitions.create('width', {
                duration: '1s'
              }),
              bgcolor: theme.palette.gray.white,
              ...(collapse && {
                width: COLLAPSE_WIDTH,
                transition: theme.transitions.create('width', {
                  duration: '1s'
                })
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: theme.customShadows.z20,
                bgcolor: alpha(theme.palette.background.default, 0.88)
              })
            }
          }}>
          <MenuContent
            setIsSwitchAccountDialogOpen={setIsSwitchAccountDialogOpen}
            isCollapse={collapse}
            setIsCollapse={setCollapse}
          />
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  setIsSwitchAccountDialogOpen: PropTypes.func
};

export default DashboardSidebar;
