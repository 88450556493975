import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faFlag, faCopy, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { suggestionTargetTypesEnum } from 'helpers/enums';
import { handleRejectSuggestion } from 'helpers/suggestionMethods';
import { DocumentStateContext } from 'providers';
import { useStoreFields } from 'stores';

export const SimplificationActions = ({
  suggestionText,
  simplificationId,
  handleApplySuggestion,
  handleDialogClose,
  sentenceSuggestions,
  setSentenceSuggestions,
  trackMixpanelData,
  selectedText
}) => {
  const { connection } = useContext(DocumentStateContext);
  const { documentId, previouslyClickedSection } = useStoreFields([
    'documentId',
    'previouslyClickedSection'
  ]);

  const [t] = useTranslation('common');
  const theme = useTheme();

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = suggestionText => {
    setShowTooltip(true);
    navigator?.clipboard?.writeText(suggestionText);

    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const setParagraphSuggestions = (suggestions, rejectedReplacement) => {
    const filteredArray = suggestions?.filter(item => item.text !== rejectedReplacement);
    setSentenceSuggestions(filteredArray);
  };

  const tooltipOffset = {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -10]
          }
        }
      ]
    }
  };

  const useStyles = makeStyles({
    simplificationActionsButton: {
      padding: 0,
      width: '32px',
      height: '32px'
    },
    applySimplificationButton: {
      backgroundColor: theme.palette.active.dark,
      '&:hover': { backgroundColor: theme.palette.active.dark }
    }
  });

  const classes = useStyles();

  return (
    <Stack direction='row' alignSelf='flex-end' sx={{ pb: '10px' }}>
      <Tooltip title={t('reject')} placement='top' slotProps={tooltipOffset}>
        <IconButton
          data-testid={`rejectSimplification-${simplificationId}`}
          className={classes.simplificationActionsButton}
          onClick={() => {
            handleRejectSuggestion(
              connection,
              documentId,
              suggestionText,
              selectedText || previouslyClickedSection.text,
              suggestionTargetTypesEnum.PARAGRAPH,
              sentenceSuggestions,
              setParagraphSuggestions
            );
            trackMixpanelData(true, suggestionText);
            sentenceSuggestions.length === 1 && handleDialogClose();
          }}>
          <FontAwesomeIcon icon={faFlag} style={{ width: '12px', height: '20px' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('copy')} placement='top-start' slotProps={tooltipOffset}>
        <div>
          <Tooltip
            data-testid='simplificationCopiedTooltip'
            title={t('copied')}
            open={showTooltip}
            placement='top'
            slotProps={tooltipOffset}>
            <IconButton
              data-testid='copySimplification'
              className={classes.simplificationActionsButton}
              sx={{
                ml: 1,
                mr: '15px'
              }}
              onClick={() => handleCopy(suggestionText)}>
              <FontAwesomeIcon icon={faCopy} style={{ width: '14px', height: '20px' }} />
            </IconButton>
          </Tooltip>
        </div>
      </Tooltip>
      <Tooltip title={t('apply')} placement='top' slotProps={tooltipOffset}>
        <IconButton
          data-testid={`applySimplification-${simplificationId}`}
          className={clsx([classes.simplificationActionsButton, classes.applySimplificationButton])}
          onClick={() => handleApplySuggestion(simplificationId, suggestionText)}
          sx={{
            mr: '15px'
          }}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{ width: '13px', height: '20px', color: theme.palette.gray.white }}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

SimplificationActions.propTypes = {
  suggestionText: PropTypes.string,
  simplificationId: PropTypes.string,
  handleApplySuggestion: PropTypes.func,
  handleDialogClose: PropTypes.func,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  trackMixpanelData: PropTypes.func,
  selectedText: PropTypes.string
};
