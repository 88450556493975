import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ParagraphLevelSuggestionsDialogTitle, Scrollbar } from 'components';
import { SimplificationLoader } from 'components/SimplificationLoader/SimplificationLoader';
import { MESSAGE_TYPES } from 'helpers';
import { useSimplifications } from 'hooks';
import { DocumentStateContext } from 'providers';
import { useStoreFields } from 'stores';

import { useStyles } from './ParagraphLevelSuggestionsDialog.css';
import { SimplificationItem } from '../SimplificationItem/SimplificationItem';

export const ParagraphLevelSuggestionDialog = ({
  isFetchingSuggestions,
  sentenceSuggestions,
  setSentenceSuggestions,
  quillEditor,
  dialogRef,
  scrollRef,
  setSelectedText,
  selectedText,
  isRephraseSelected,
  suggestion,
  setExpandedAccordion,
  setIsDialogOpen,
  title = 'rephraseText'
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const selectedIndex = selectedText.index + selectedText.length;
  const [rephraseDialogPosition, setRephraseDialogPosition] = useState({ top: 0, left: 0 });

  const container = document.getElementById('editorContainer');
  const rect = container?.getBoundingClientRect();
  const rectContainerWidth = rect?.width + rect?.width * 0.08;
  const DIALOG_MAX_WIDTH = rectContainerWidth <= 420 ? rect?.width : 420;

  const { connection } = useContext(DocumentStateContext);
  const classes = useStyles(theme);
  const {
    selectedSimplification,
    setSelectedSimplification,
    isFetchingSelectedTextSimplifications,
    setIsFetchingSelectedTextSimplifications
  } = useStoreFields([
    'selectedSimplification',
    'setSelectedSimplification',
    'isFetchingSelectedTextSimplifications',
    'setIsFetchingSelectedTextSimplifications'
  ]);

  const { selectedTextSuggestions, setSelectedTextSuggestions } = useSimplifications({
    quillEditor
  });

  useEffect(() => {
    if (!isRephraseSelected && !suggestion) {
      handleDialogClose();
    }
  }, [isRephraseSelected]);

  useEffect(() => {
    getDialogPosition();
  }, []);

  useEffect(() => {
    if (isRephraseSelected && selectedText) {
      setIsFetchingSelectedTextSimplifications(true);
      connection?.on(
        MESSAGE_TYPES.RECEIVE_SELECTED_TEXT_SIMPLIFICATIONS,
        (resp, mlDataNotPresentResult) => {
          if (mlDataNotPresentResult) {
            setSelectedTextSuggestions([]);
            setSentenceSuggestions([]);
            setIsFetchingSelectedTextSimplifications(false);
          } else if (
            selectedText?.startIndex === resp.index &&
            selectedText?.length === resp.endIndex - resp.startIndex
          ) {
            setSelectedTextSuggestions(resp);
            setSentenceSuggestions(resp.simplifications);
            setIsFetchingSelectedTextSimplifications(false);
          }
        }
      );
    }
  }, [selectedIndex]);

  const isFetchingCorrectSelectedTextSuggestions =
    isFetchingSelectedTextSimplifications ||
    (!isFetchingSelectedTextSimplifications &&
      selectedTextSuggestions?.startIndex !== selectedText?.index &&
      selectedTextSuggestions?.length !== selectedText?.length);

  const isFetchingData = isRephraseSelected
    ? isFetchingCorrectSelectedTextSuggestions
    : isFetchingSuggestions;

  useEffect(() => {
    if (!isFetchingData) {
      getDialogPosition();
    }
  }, [isFetchingData]);

  const simplificationContent = () => {
    return (
      <Scrollbar sx={{ maxHeight: '300px' }}>
        <DialogActions
          disableSpacing
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {sentenceSuggestions?.map((sentenceSuggestion, index) => {
            return (
              <SimplificationItem
                key={sentenceSuggestion.simplificationId}
                index={index}
                sentenceSuggestion={sentenceSuggestion}
                sentenceSuggestions={sentenceSuggestions}
                setSentenceSuggestions={setSentenceSuggestions}
                quillEditor={quillEditor}
                dialogRef={dialogRef}
                scrollRef={scrollRef}
                endIndex={selectedIndex}
                startIndex={selectedText.index}
                selectedText={quillEditor?.getText(
                  selectedText.index,
                  selectedIndex - selectedText.index
                )}
                setSelectedText={setSelectedText}
                suggestion={suggestion}
                setExpandedAccordion={setExpandedAccordion}
              />
            );
          })}
        </DialogActions>
      </Scrollbar>
    );
  };

  const getDialogPosition = () => {
    const startBounds = quillEditor?.getBounds(selectedText.index);
    const endBounds = quillEditor?.getBounds(selectedIndex);

    let left;
    let top;
    const offset = dialogRef?.current?.offsetWidth < 400 ? 55 : 30;

    if (rectContainerWidth && startBounds.bottom === endBounds.bottom) {
      if (rectContainerWidth - DIALOG_MAX_WIDTH >= startBounds.left) {
        left = startBounds.left - offset;
      } else {
        left = rectContainerWidth - DIALOG_MAX_WIDTH;
      }

      top = startBounds.top + startBounds.height;
    } else {
      left = -30;
      top = endBounds.top + endBounds.height;
    }

    setRephraseDialogPosition({ top: top, left: left });
  };

  const handleDialogClose = () => {
    quillEditor.formatText(selectedText.index, selectedText.length, 'paragraph_suggestion', false);

    if (!selectedSimplification) {
      setSelectedText(null);
      setSentenceSuggestions(null);
    }

    if (selectedSimplification) {
      setSelectedSimplification(null);
      setIsDialogOpen(false);
      setExpandedAccordion(null);
    }

    connection?.invoke(MESSAGE_TYPES.CLOSE_SIMPLIFICATIONS);
  };

  return (
    <Dialog
      data-testid='paragraphLevelSuggestionsDialog'
      slotProps={{
        backdrop: {
          style: {
            opacity: 0
          }
        }
      }}
      sx={{
        position: 'absolute',
        '& .MuiDialog-container': {
          position: 'relative',
          top: rephraseDialogPosition.top,
          left: `${rephraseDialogPosition.left}px`,
          height: 'auto',
          width: isFetchingData ? DIALOG_MAX_WIDTH : 'auto',
          maxWidth: DIALOG_MAX_WIDTH,
          minWidth: '300px'
        }
      }}
      fullWidth={isFetchingData}
      container={() => document.getElementById('popupParentContainer')}
      maxWidth={DIALOG_MAX_WIDTH}
      PaperProps={{ className: classes.paper }}
      open={selectedText?.length > 1}
      onClick={e => e.stopPropagation()}
      onClose={handleDialogClose}>
      <ParagraphLevelSuggestionsDialogTitle handleDialogClose={handleDialogClose} title={title} />
      <DialogContent
        ref={dialogRef}
        sx={{
          '&.MuiDialogContent-root': {
            padding: 0
          }
        }}>
        {isFetchingData ? (
          <SimplificationLoader height={30} my={6} fontSize={16} />
        ) : (
          simplificationContent()
        )}
        {!isFetchingData && sentenceSuggestions?.length === 0 && (
          <Box
            data-testid='noSuggestionsBox'
            sx={{
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography
              data-testid='noSuggestionsText'
              variant='body3'
              sx={{
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                fontSize: theme.typography.pxToRem(16)
              }}>
              {t('noReplacementsAvailable')}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

ParagraphLevelSuggestionDialog.propTypes = {
  isFetchingSuggestions: PropTypes.bool,
  sentenceSuggestions: PropTypes.array,
  setSentenceSuggestions: PropTypes.func,
  quillEditor: PropTypes.object,
  dialogRef: PropTypes.object,
  scrollRef: PropTypes.object,
  setSelectedText: PropTypes.func,
  selectedText: PropTypes.object,
  isRephraseSelected: PropTypes.bool,
  suggestion: PropTypes.object,
  setExpandedAccordion: PropTypes.func,
  setIsDialogOpen: PropTypes.func,
  title: PropTypes.string
};
