import React from 'react';

import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';

import { CustomCheckbox } from 'components';
import { orderByEnum } from 'helpers';

export const ListHead = ({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  selectAllDisabled,
  dataCy
}) => {
  const theme = useTheme();

  return (
    <TableHead sx={{ display: 'flex' }}>
      <TableRow
        sx={{
          borderTop: `1px solid ${theme.palette.gray.light}`,
          width: '100%'
        }}>
        {!selectAllDisabled && (
          <TableCell
            padding='checkbox'
            sx={{
              py: 0.85,
              '&.MuiTableCell-root': {
                backgroundColor: theme.palette.gray.white,
                pl: '20px'
              }
            }}>
            <CustomCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              dataCy={dataCy}
            />
          </TableCell>
        )}
        {headLabel.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              py: 0.85,
              '&.MuiTableCell-root': {
                backgroundColor: theme.palette.gray.white,
                backgroundImage: 'none',
                pl: '15px',
                '&.MuiTableCell-root:first-of-type': {
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px'
                },
                '&.MuiTableCell-root:last-of-type': {
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px'
                }
              }
            }}>
            <TableSortLabel
              sx={{
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                fontSize: theme.typography.pxToRem(14),
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.gray.main,
                cursor: headCell.disableOrder ? 'default' : 'pointer',
                '& .MuiTableSortLabel-icon': {
                  width: '14px',
                  height: '14px'
                }
              }}
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : orderByEnum.asc}
              onClick={() => !headCell.disableOrder && onRequestSort && onRequestSort(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === orderByEnum.desc ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ListHead.propTypes = {
  order: PropTypes.oneOf([orderByEnum.asc, orderByEnum.desc]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  selectAllDisabled: PropTypes.bool,
  dataCy: PropTypes.string
};
