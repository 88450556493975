const initialState = {
  isDataLoaded: false,
  isMLDataPresent: false,
  documentName: '',
  documentContent: null,
  documentLastSaveTime: null,
  documentMetadata: null,
  documentSuggestions: [],
  currentViewers: [],
  numberOfEdits: 0,
  numberOfSessions: 0,
  paragraphData: null
};

export const documentDataStore = set => ({
  ...initialState,
  setDocumentContent: value =>
    set(state => ({
      documentContent: value,
      isDataLoaded: value !== null && state.documentMetadata !== null
    })),
  setDocumentMetadata: value =>
    set(state => ({
      documentMetadata: value,
      documentName: value.name,
      documentLastSaveTime: value.lastSaveTime,
      numberOfEdits: value.numberOfEdits,
      numberOfSessions: value.numberOfSessions,
      isDataLoaded: value !== null && state.documentContent !== null,
      isMLDataPresent: !value.mlDataNotPresent
    })),
  setCurrentViewers: value => set({ currentViewers: value }),
  setParagraphData: value => set({ paragraphData: value }),
  resetDocumentDataStore: () => set(initialState)
});
