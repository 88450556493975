import axios from 'axios';
import { getIdToken } from 'firebase/auth';

import { auth, getApiCoreUrl, paypalAuthorizationEndpoint } from 'helpers';
import { IS_PAYPAL_USER, PROVIDER_KEY, TOKEN_MANAGER_KEY } from 'providers';

const getPingToken = (code, setTokenReceived) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: code,
      TenantName: 'paypal',
      AuthProvider: 'PingId'
    },
    url: `${getApiCoreUrl()}/api/v1/Login/token`
  };

  return axios(options).then(res => {
    const expiration = new Date().getTime() + res.data.expires_in * 1000;

    const tokenManager = {
      accessToken: res.data.access_token,
      expirationTime: expiration
    };

    setToken(tokenManager, setTokenReceived);
  });
};

const getToken = async () => {
  const tokenManager = getTokenManager();
  if (getProvider() === window.REACT_APP_PAYPAL_PROVIDER) {
    if (tokenManager && new Date().getTime() >= tokenManager['expirationTime']) {
      // if token is expired clear the session storage and redirect to the ping login page
      localStorage.removeItem(TOKEN_MANAGER_KEY);
      localStorage.removeItem(PROVIDER_KEY);
      window.location = paypalAuthorizationEndpoint;
    }

    return tokenManager['accessToken'];
  }

  const currentUser = auth?.currentUser;
  const token = currentUser && (await getIdToken(currentUser));

  return token;
};

const getTokenManager = () => {
  return JSON.parse(localStorage.getItem(TOKEN_MANAGER_KEY));
};

const getProvider = () => {
  if (location.pathname.includes(window.REACT_APP_PAYPAL_CLIENT)) {
    localStorage.setItem(PROVIDER_KEY, window.REACT_APP_PAYPAL_PROVIDER);
    localStorage.setItem(IS_PAYPAL_USER, true);
  }

  return localStorage.getItem(PROVIDER_KEY);
};

const isPingUserLoggedIn = () => {
  return getProvider() === window.REACT_APP_PAYPAL_PROVIDER && getTokenManager();
};

const setToken = (token, setTokenReceived) => {
  localStorage.setItem(TOKEN_MANAGER_KEY, JSON.stringify(token));
  setTokenReceived?.(true);
};

export const tokenService = {
  getPingToken,
  getToken,
  getProvider,
  isPingUserLoggedIn
};
